export default {
  'slot1': "8225423388",
  'slot2': "8340123396",
  'slot3': "8194984863",
  'firebaseConfig': {
  apiKey: "AIzaSyC1KmN83lJy9EQElFUCAaWTUfyoa0qyFko",
  authDomain: "news12-bid.firebaseapp.com",
  projectId: "news12-bid",
  storageBucket: "news12-bid.appspot.com",
  messagingSenderId: "237082632064",
  appId: "1:237082632064:web:b635ffe792356f648a6b65",
  measurementId: "G-HCBK8RZ510"
  }
}